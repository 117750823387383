<p-dialog (onHide)="closeDialog()"
          [dismissableMask]="false"
          position="top"
          [contentStyle]="{'overflow':'visible'}"
          [(visible)]="displayDialog"
          [responsive]="true"
          [style]="{'width':'1000px'}"
          [modal]="true"
          [breakpoint]="640">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-in-alt mg-r-5"></i> Ajouter un lot
    </div>
  </ng-template>


  <!-- PANEL ARTICLE -->
  <p-panel header="DENRÉE (produit déclinaison)" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="d-flex justify-content-between ">

      <div class=" d-flex justify-content-between align-items-center">
        <label class="genlabel no-overflow mg-r-15 ">
          <span> Nom : </span>
        </label>
        <input type="text" pInputText [value]="produitDeclinaison?.libelle" disabled="true">
      </div>

      <div class=" d-flex justify-content-between align-items-center">
        <label class="genlabel no-overflow mg-r-15 ">
          <span> Code : </span>
        </label>
        <input type="text" pInputText [value]="produitDeclinaison?.code" disabled="true">
      </div>


      <div class=" d-flex justify-content-between align-items-center">
        <label class="genlabel no-overflow mg-r-15 ">
          <span> Declinaison : </span>
        </label>
        <input type="text" pInputText [value]="produitDeclinaison?.declinaison?.libelle" disabled="true">
      </div>
    </div>
  </p-panel>


  <!-- PANEL ARTICLE -->
  <p-panel header="ARTICLE" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="d-flex flex-row mg-b-20">
      <div class="mg-r-15 ">
        <label class="genlabel no-overflow">
          <span> Unité de production : </span>
        </label>
        <p-dropdown [options]="uniteProductionList"
                    [(ngModel)]="uniteProductionSelected"
                    (ngModelChange)="onChangeUdp($event)"
                    [style]="{'max-width':'350px', 'min-width':'350px' }"
                    optionLabel="libelle"
                    filter="true">
        </p-dropdown>
      </div>
      <div class="mg-r-15">
        <label class="genlabel no-overflow">
          <span> Fournisseur : </span>
        </label>
        <p-dropdown [options]="fournisseurList"
                    [(ngModel)]="fournisseurSelected"
                    (ngModelChange)="onChangeFournisseur($event)"
                    [style]="{'max-width':'350px', 'min-width':'350px' }"
                    optionLabel="libelle"
                    filter="true">
        </p-dropdown>
      </div>
    </div>


    <dx-data-grid
      [dataSource]="catalogueAchatList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(750)"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="produitArticle.libelle" alignment="left" caption="Article" [width]="300"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="produitArticle.reference" alignment="left" caption="Référence" [width]="300"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="groupeAchatCaList" alignment="left" caption="Prix (UC)" [width]="200"
                  [allowFiltering]="true"
                  cellTemplate="prixCellTemplate">

        <!-- TEMPLATE -->
      </dxi-column>
      <div *dxTemplate="let cell of 'prixCellTemplate'">
        <div *ngFor="let groupeAchatCa of cell.row.data?.groupeAchatCaList" class="d-flex flex-column">
          <div class="d-flex flex-row align-items-center">
            <p-radioButton [name]="'groupnameGaca'"
                           [value]="groupeAchatCa"
                           [(ngModel)]="selectedGacaViewModel"
                           class="mg-r-5"
                           (onClick)="updateGacaViewModel(groupeAchatCa, cell.row.data)">
            </p-radioButton>
            <ng-container [ngSwitch]="groupeAchatCa.prefere">
              <i *ngSwitchCase="true" class="mg-r-5 fas fa-star cursor" title="Article préféré"></i>
              <ng-container *ngSwitchCase="undefined">
                <!--                // DO NOTHING-->
              </ng-container>
            </ng-container>
            <div>
              {{groupeAchatCa.prixUC | currency:'EUR':'symbol':'1.4-4'}}
              / {{cell.row.data.uniteDeCommande.libelle | slice:0:5}}
              <span
                [title]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.row.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
            </div>
          </div>
        </div>
      </div>


      <!--    OPTION -->
      <dxo-selection mode="single"></dxo-selection>
      <dxo-pager
        [showPageSizeSelector]="false"
        [showNavigationButtons]="true"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} Article(s)">
      </dxo-pager>
    </dx-data-grid>

  </p-panel>

  <!--PANEL STOCK -->
  <p-panel header="STOCK" [collapsed]="false" [style]="{'margin-bottom': '10px'}">
    <form [formGroup]="form">
      <!--COL GAUCHE -->
      <div class="d-flex justify-content-around">

        <div class="d-flex flex-column">
          <div class="mg-b-10 d-flex justify-content-between align-items-center">
            <label class="genlabel no-overflow mg-r-15 ">
              <span> Zone de Stockage* : </span>
            </label>
            <p-dropdown [options]="zoneStockageList"
                        editable="true"
                        filter="true"
                        (onChange)="onChangeZoneStokage($event)"
                        placeholder="Selectionner..."
                        name="zoneStockage"
                        [style]="{'max-width':'300px', 'min-width':'300px' }"
                        optionLabel="libelle">
            </p-dropdown>
          </div>

          <div class="mg-b-10 d-flex justify-content-between align-items-center">
            <label class="genlabel no-overflow mg-r-15 ">
              <span> Date d'entrée : </span>
            </label>
            <p-calendar
              firstDayOfWeek="1"
              id="dateEntree"
              formControlName="dateEntree"
              dateFormat="DD d MM yy"
              [style]="{'font-weight':'bold'}"
              [locale]="localeFr"
              [showIcon]="true"
              [showWeek]="true">
            </p-calendar>
          </div>

          <div class="mg-b-10 d-flex justify-content-between align-items-center">
            <label class="genlabel no-overflow mg-r-15 ">
              <span> DLC : </span>
            </label>
            <p-calendar
              firstDayOfWeek="1"
              id="dlc"
              formControlName="dlc"
              dateFormat="DD d MM yy"
              [style]="{'font-weight':'bold'}"
              [locale]="localeFr"
              [showIcon]="true"
              [showWeek]="true">
            </p-calendar>
          </div>
        </div>

        <!--COL DROITE -->
        <div class="d-flex flex-column">
          <div class="mg-b-10 d-flex justify-content-between align-items-center">
            <label class="genlabel no-overflow mg-r-15 ">
              <span> Lot fabriquant : </span>
            </label>
            <input type="text" pInputText id="lotFabriquant" formControlName="lotFabriquant" [style]="{'size':'200px'}">
          </div>

          <div class="mg-b-10 d-flex justify-content-between align-items-center">
            <label class="genlabel no-overflow mg-r-15 ">
              <span title="Quantité en US"> Quantité en US* : </span>
            </label>
            <div>
              <div class="p-inputgroup">
                <input type="text" pInputText id="quantite" formControlName="quantite" [style]="{'size':'200px'}">
                <div class="input-group-append ">
                  <span class="input-group-text"
                        *ngIf="!utils.isNullOrEmpty(uniteDeStockage)">{{this.uniteDeStockage?.abreviation}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="mg-b-10 d-flex justify-content-between align-items-center">
            <label class="genlabel no-overflow mg-r-15 ">
              <span title="Prix d'achat en UF"> Prix d'achat en UF : </span>
            </label>
            <div>
              <div class="p-inputgroup">
                <input type="text" pInputText id="prixUnitaire" formControlName="prixUnitaire" [style]="{'size':'200px'}">
                <div class="input-group-append ">
                  <span class="input-group-text"
                        *ngIf="!utils.isNullOrEmpty(uniteDeFacturation)">€/{{this.uniteDeFacturation?.abreviation}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-panel>


  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
    </div>
    <div>
    <span class="mg-r-5">
      <yo-button iconClass="fas fa-save" (onClick)="ajouterLot()"
              [disabled]="isDisabledSaveBtn()" tooltip="Enregistrer" buttonClass="cta-success">
      </yo-button>
    </span>
      <span>
      <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
              buttonClass="cta-inner-delete">
      </yo-button>
    </span>
    </div>
  </div>
</p-dialog>
