// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.6-rc-9666(1ebb0ee7c0)-C04/10/2023-15:56:54-B04/10/2023-15:59:38' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.6-rc-9666(1ebb0ee7c0)-C04/10/2023-15:56:54-B04/10/2023-15:59:38",
  branch: "master",
  latestTag: "5.6-rc",
  revCount: "9666",
  shortHash: "1ebb0ee7c0",
  longHash: "1ebb0ee7c01bc1746cf94f0b30cb1d5840ee1c19",
  dateCommit: "04/10/2023-15:56:54",
  dateBuild: "04/10/2023-15:59:38",
  buildType: "Ansible",
  } ;
